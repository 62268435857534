<template>
    <div>
        <div class="return-information-status" :class="'status-'+status" data-testid="return-information-status">
            <frm-spinner v-if="status === 'fetching'" class="spinner" />
            <frm-icon v-else-if="statusIcon" class="icon" :name="statusIcon" />

            <a v-if="status === 'new-information-available'" class="message" href="#" @click.prevent="reload()">
                {{ statusMessage }}
            </a>
            <span v-else-if="statusMessage" class="message">
                {{ statusMessage }}
            </span>
        </div>
        <div v-if="showCountInvoicesWhichNeedAction" class="alert alert--warning" data-testid="return-information:invoices-need-action-alert">
            <frm-icon class="alert__icon" name="exclamation-mark" />
            <div class="alert__content">
                <a href="#" @click.prevent="showInvoicedWhichNeedAction()">
                    {{ $tc('financial.messages.invoices-need-action', countInvoicesWhichNeedAction) }}
                </a>
            </div>
        </div>

        <frm-overview ref="overview" :config="overviewConfig" @filters-changed="activeFilters = $event" />
    </div>
</template>

<script>

import axios from 'axios';
import HandlesErrorResponses from '../mixins/HandlesErrorResponses';
import HandlesInstitute from '../mixins/HandlesInstitute';
import HandlesJobs from '../mixins/Overview/HandlesJobs';

export default {
    mixins: [HandlesErrorResponses, HandlesInstitute, HandlesJobs],
    props: {
        overviewConfig: {
            type: Object,
            required: true,
        },
        countInvoicesWhichNeedActionUrl: {
            type: String,
            required: true,
        },
        fetchReturnInformationUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            countInvoicesWhichNeedAction: 0,
            status: 'fetching',
            jobMessage: '',
            activeFilters: [],
        };
    },
    mounted() {
        this.fetchInvoiceCountWhichNeedAction();
        this.fetchLatestReturnInformation();
    },
    watch: {
        instituteId() {
            this.fetchInvoiceCountWhichNeedAction();
        },
    },
    computed: {
        showCountInvoicesWhichNeedAction() {
            const statusActive = this.activeFilters
                .find((filter) => filter.attribute === 'status' && filter.value === this.invoicesWhichNeedActionFilters.status);

            return this.countInvoicesWhichNeedAction > 0 && !statusActive;
        },

        invoicesWhichNeedActionFilters() {
            return {
                institute_id: this.instituteId,
                date: [],
                status: 'declined,partially_declined,approved_credit_to_insurer',
            };
        },
        statusIcon() {
            switch (this.status) {
                case 'new-information-not-available':
                    return 'check';
                case 'new-information-available':
                    return 'exclamation-mark';
                case 'error':
                    return 'error';
                default:
                    return null;
            }
        },
        statusMessage() {
            if (this.status === 'fetching') {
                return this.jobMessage;
            }

            return this.$t(`financial.messages.return-information.${this.status}`);
        },
    },
    methods: {
        async fetchInvoiceCountWhichNeedAction() {
            try {
                this.countInvoicesWhichNeedAction = (await axios.get(this.countInvoicesWhichNeedActionUrl, {
                    params: {
                        institute_id: this.instituteId,
                    },
                })).data.count;
            } catch (err) {
                await this.handleErrorResponse(err);
            }
        },
        async fetchLatestReturnInformation() {
            const jobId = (await axios.get(this.fetchReturnInformationUrl, {
                params: {
                    institute_id: this.instituteId,
                },
            })).data.jobId || null;

            if (jobId) {
                try {
                    const newInformation = (await this.awaitJob(jobId, jobData => {
                        this.jobMessage = jobData.message;
                    })).new_return_information;

                    this.status = newInformation
                        ? 'new-information-available'
                        : 'new-information-not-available';
                } catch (err) {
                    this.status = 'error';
                }
            } else {
                this.status = 'new-information-not-available';
            }
        },
        showInvoicedWhichNeedAction() {
            this.$refs.overview.setFilters(this.invoicesWhichNeedActionFilters);
        },
        reload() {
            this.status = 'new-information-not-available';
            this.fetchInvoiceCountWhichNeedAction();
            this.$refs.overview.refresh();
        },
    },
};
</script>

<style lang="scss" scoped>
.return-information-status {
    align-items: center;
    background: $color__primary--light-highlight;
    border: 1px solid $color__primary--highlight;
    display: flex;
    padding: rhythm(.75);
    position: fixed;
    right: $main-content-padding-x;
    top: calc(var(--app__header__height) + 6px);
    width: auto;
    z-index: 99;

    .spinner {
        & + .message {
            margin-left: rhythm(1);
        }
    }

    .icon {
        height: rhythm(2);
        fill: $color__primary;
        width: rhythm(2);

        & + .message {
            margin-left: rhythm(1);
        }
    }

    .message {
        color: $color__primary;
    }

    &.status-new-information-available {
        background: $color__attention;
        border-color: $color__attention--dark;

        .icon {
            fill: $color__warning;
        }
    }

    &.status-error {
        background: $color__attention--light;
        border-color: $color__error;

        .icon {
            fill: $color__error;
        }

        .message {
            color: $color__error;
        }
    }
}
</style>
