<template>
    <div class="form-smart-text">
        <div
            :id="id"
            ref="input"
            v-bind="$attrs"
            :class="[ textareaClass, { 'form-input--initial': isInitial } ]"
            :contenteditable="!disabled"
            :disabled="disabled"
            :required="required"
            :autofocus="autofocus"
            :tabindex="disabled ? -1 : tabindex"
            class="form-textarea form-smart-text__input form-smart-text__input--textarea"
            @blur="blur"
            @change="$emit('change', getPlainTextValue($event.target.innerHTML))"
            @contextmenu="$event.preventDefault()"
            @focus="focus"
            @input="$emit('input', getPlainTextValue($event.target.innerHTML))"
            @keydown="closeLinkContextMenu"
        />
        <div
            v-if="showPlaceholder"
            :class="{'form-smart-text__placeholder--disabled': disabled}"
            class="form-smart-text__placeholder"
        >
            {{ placeholder }}
        </div>
        <textarea
            :disabled="disabled"
            :name="name"
            :required="required"
            :value="value"
            autocomplete="off"
            class="form-smart-text__hidden"
            tabindex="-1"
        />
        <div class="form-smart-text__buttons">
            <button
                :aria-label="buttonTooltip"
                :disabled="disabled || loading"
                class="form-smart-text__button hint--left js-smart-text-button"
                type="button"
                tabindex="-1"
            >
                <frm-icon
                    :class="{'form-smart-text__button-icon--error': error}"
                    :name="buttonIconName"
                    class="form-smart-text__button-icon"
                />
            </button>
            <button
                v-if="showResetButton"
                :disabled="disabledComputed"
                class="form-smart-text__button form-smart-text__button--reset"
                type="button"
                tabindex="-1"
                data-testid="form-smart-text:reset"
                @click="reset()"
            >
                <frm-icon
                    :class="{'form-smart-text__button-icon--error': error}"
                    name="general-delete"
                    class="form-smart-text__button-icon-reset"
                />
            </button>
        </div>
    </div>
</template>

<script>
    import S from 'string';
    import DataText from './DataText.vue';
    import $ from 'jquery';

    export default {
        extends: DataText,

        props: {
            id: {
                type: String,
                default() {
                    return 'smart_textarea_' + this.uniqueIdentifier;
                },
            },
        },

        methods: {
            initSpecialBehaviour() {
                const getSelectedElementIndex = ($allLinks) => {
                    const selection = window.getSelection();
                    const selectedText = selection.toString();

                    if (!$allLinks.length) {
                        return null;
                    }

                    if (selectedText.startsWith('[') && selectedText.endsWith(']')) {
                        const $elem = $allLinks.filter((_, element) => $(element).text() === selectedText);

                        if ($elem.length) {
                            return $allLinks.index($elem.get(0));
                        }
                    }

                    return null;
                };

                $(this.$refs.input).on('keydown', (event) => {
                    if (event.key === 'Tab') {
                        const $allLinks = $(this.$refs.input).find('.form-smart-text__selectable');

                        if (!$allLinks.length) {
                            return;
                        }

                        const selectedElementIndex = getSelectedElementIndex($allLinks);

                        if (selectedElementIndex === null) {
                            event.preventDefault();
                            this.selectFirstLink();
                        } else {
                            const nextLinkIndex = selectedElementIndex + (event.shiftKey ? -1 : 1);

                            if (nextLinkIndex > -1 && nextLinkIndex < $allLinks.length) {
                                event.preventDefault();
                                this.selectElement($allLinks.get(nextLinkIndex));
                            }
                        }
                    }
                });
            },

            /**
             * @param {string} text
             *
             * @return {string}
             */
            getPlainTextFromPaste(text) {
                return text;
            },

            /**
             * @param {string} value
             *
             * @returns {string}
             */
            getPlainTextValue(value) {
                return S(value)
                    .replace(/<div><br\/?><\/div>/g, '<div></div>')
                    .replace(/<\/div>/g, '</div>\n')
                    .replace(/<br\/?>/g, '\n')
                    .trim()
                    .stripTags()
                    .stripRight('\n')
                    .decodeHTMLEntities()
                    .toString();
            },
        },
    };
</script>
