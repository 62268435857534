<template>
    <section class="modal-component modal-component--dialog" data-testid="dialog">
        <div class="modal-component__content" v-html="message" />

        <div class="modal-component__buttons">
            <div class="button-group">
                <button class="button button--grouped" data-testid="dialog-ok" type="button" @click="$emit('ok')">
                    <span class="button__icon-group">
                        <frm-icon class="button__icon" name="check" />
                        <span>{{ ok }}</span>
                    </span>
                </button>
                <button v-if="cancel" class="button button--text button--grouped" data-testid="dialog-cancel" type="button" @click="$emit('cancel')">
                    {{ cancel }}
                </button>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        props: {
            message: {
                type: String,
                required: true,
            },

            ok: {
                type: String,
                required: true,
            },

            cancel: {
                type: String,
                default: null,
            },
        },

        mounted() {
            document.addEventListener('keydown', this.onKeydown);
        },

        beforeDestroy() {
            document.removeEventListener('keydown', this.onKeydown);
        },

        methods: {
            onKeydown(event) {
                switch (event.key) {
                    case 'Enter':
                        this.$emit('ok');
                        break;
                    case 'Escape':
                        this.$emit('cancel');
                        break;
                }
            },
        },
    };
</script>
