// @vue/component
import jsonApi from '../../json-api-client';
import apiUrl from '../../services/apiUrl';
import JobPendingDialog from '../../components/JobPendingDialog.vue';
import ModalManager from '../../services/modalManager';
import DialogManager from '../../services/DialogManager';

const fetchJob = async(jobId) => (await jsonApi.axios.get(apiUrl(`/job/${jobId}`))).data;
const jobIsCompleted = (job) => job.attributes.status === 'Completed';
const jobIsFailed = (job) => job.attributes.status === 'Failed';

const calculateTimeout = (retryCount) => {
    if (retryCount < 8) {
        return 250;
    } else if (retryCount < 12) {
        return 500;
    } else if (retryCount < 16) {
        return 1000;
    } else {
        return 2000;
    }
};

export default {
    data() {
        return {
            jobDialogShown: false,
        };
    },

    methods: {
        awaitJob(jobId, onProcessingJob = null) {
            return new Promise((resolve, reject) => {
                const start = Date.now();

                const poll = async(retryCount = 0) => {
                    const jobResponse = await fetchJob(jobId);
                    const jobData = jobResponse.data;

                    if (jobIsCompleted(jobData)) {
                        if (this.jobDialogShown) {
                            this.closeDialog();
                        }

                        resolve(jobData.attributes);
                    } else if (jobIsFailed(jobData)) {
                        if (this.jobDialogShown) {
                            this.closeDialog();
                        }

                        const error = jobResponse.included?.find((included) => included.type === 'error')?.attributes?.detail;

                        DialogManager.errors(error).then();

                        reject(new Error(error));
                    } else {
                        if (onProcessingJob) {
                            onProcessingJob(jobData.attributes);
                        } else {
                            this.$store.commit('core/setPendingJobMessage', jobData.attributes.message || '');

                            if (!this.jobDialogShown && (Date.now() - start) > window.frm.state.jobDialogThresholdSeconds * 1000) {
                                ModalManager.openComponentInModal(JobPendingDialog, {}, {}, 'small', false).then();
                                this.jobDialogShown = true;
                            }
                        }

                        setTimeout(() => poll(retryCount + 1), calculateTimeout(retryCount));
                    }
                };

                poll().then();
            });
        },

        async awaitAndProcessJob(job) {
            const jobData = await this.awaitJob(job.id);

            return this.onJobComplete(job, jobData);
        },

        closeDialog() {
            ModalManager.closeModal();
            this.jobDialogShown = false;
        },

        async onJobComplete(job, jobData) {
            if (job.show_message_on_complete && jobData.message) {
                await DialogManager.alert(jobData.message);
            }

            try {
                if (job.on_complete) {
                    return this.handleUrl(job.on_complete, job.type, 'GET', job.target);
                }

                if (this.refresh) {
                    await this.refresh();
                }
            } catch (error) {
                await this.handleErrorResponse(error);
            }
        },
    },
};
