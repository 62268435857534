<template>
    <button type="button" :class="btnClass" :disabled="loading" @click.prevent.stop="executeJob()">
        <frm-spinner v-if="loading" />
        <slot v-else />
    </button>
</template>

<script>

import axios from 'axios';
import HandlesJobs from '../mixins/Overview/HandlesJobs';
import HandlesErrorResponses from '../mixins/HandlesErrorResponses';
import HandlesActions from '../mixins/Overview/HandlesActions';

export default {
    mixins: [HandlesErrorResponses, HandlesJobs, HandlesActions],

    props: {
        url: {
            required: true,
            type: String,
        },

        method: {
            type: String,
            default: 'GET',
        },

        btnClass: {
            type: String,
            default: 'button button--secondary',
        },
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        async executeJob() {
            this.loading = true;

            const job = await this.fetchJob();

            if (!job) {
                this.loading = false;
                return;
            }

            try {
                await this.awaitAndProcessJob(job);
            } finally {
                this.loading = false;
            }
        },

        async fetchJob() {
            try {
                return (await axios({
                    method: this.method,
                    url: this.url,
                })).data;
            } catch (err) {
                await this.handleErrorResponse(err);

                return null;
            }
        },
    },
};
</script>
