<template>
    <div class="patient-card" :class="{ 'patient-card--birthday': showBirthdayMessage }" data-testid="patient-card">
        <div class="patient-card__main">
            <figure class="patient-card__avatar">
                <!--suppress HtmlUnknownTarget -->
                <img v-if="photo" :alt="name" :src="`/assets/attachments/thumbnails/${photo}`" class="patient-card__photo">
                <frm-icon v-else name="patient" class="patient-card__icon" />
            </figure>
            <div class="patient-card__content">
                <h3 class="patient-card__name" :title="name">
                    {{ name }} <span v-if="gender">({{ $t(`patient.values.gender-denotation-${gender}`) }})</span>
                </h3>
                <dl class="patient-card__details">
                    <dt v-if="birthdate" class="patient-card__detail-title" v-text="$t('patient.fields.birthdate')" />
                    <dd v-if="birthdate" class="patient-card__detail-description">
                        {{ $ds(birthdate, 'date') }}
                        <frm-deceased-icon v-if="deceasedDate" :date="$ds(deceasedDate, 'date')" />
                    </dd>
                    <dt v-if="identificationNumber" class="patient-card__detail-title" v-text="$t('patient.fields.identification_number')" />
                    <dd v-if="identificationNumber" class="patient-card__detail-description" v-text="identificationNumber" />
                </dl>
            </div>
        </div>
        <div v-if="showBirthdayMessage" class="patient-card__birthday patient-card__birthday--animate" data-testid="patient-card:birthday">
            <frm-icon name="balloon" class="patient-card__birthday-icon" />
            <span class="patient-card__birthday-text">
                {{ $t('patient.messages.birthday_on') }}
                <strong>{{ $ds(birthdate, 'dateShort') }}</strong>
            </span>
            <frm-icon name="balloon" class="patient-card__birthday-icon" />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                required: true,
            },

            birthdate: {
                type: String,
                default: null,
            },

            deceasedDate: {
                type: String,
                default: null,
            },

            gender: {
                type: String,
                default: null,
            },

            identificationNumber: {
                type: String,
                default: null,
            },

            photo: {
                type: [String, Number],
                default: null,
            },

            eventDate: {
                type: String,
                default: null,
            },
        },

        methods: {
            isBirthday(otherDate) {
                const birthdate = new Date(this.birthdate);

                return otherDate.getDate() === birthdate.getDate() && otherDate.getMonth() === birthdate.getMonth();
            },

            today() {
                if (sessionStorage.getItem('MOCK_PATIENT_CARD_TODAY')) {
                    return new Date(sessionStorage.getItem('MOCK_PATIENT_CARD_TODAY'));
                }

                return new Date();
            },
        },

        computed: {
            showBirthdayMessage() {
                if (!this.birthdate || this.deceasedDate) {
                    return false;
                }

                return this.isBirthday(this.today()) || this.isBirthday(new Date(this.eventDate));
            },
        },
    };
</script>
