export default {
    name: 'patient',
    definition: {
        name: '',
        gender: '',
        age: '',
        address: [],
        deceased: '',
        deceased_date: '',
    },
};
